import React from 'react'
import Layout from '../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../components/layouts'

import {
	Title,
	Button,
	GenericContent,
} from '../../components/ui'

import theme from '../../theme/theme'

const {
	termsConditions,
} = theme.sections

const TermsConditions = () => {
	return (
		<Layout>
			<Section id="top" paddingTop={'8em'}>
				<Container>
					<Row marginBottom={'2em'}>
						<Column>
							<Title fontSize={'3em'} marginLeft={'0'}>{termsConditions.content.title}</Title>
							<GenericContent fontSize={'1em'} dangerouslySetInnerHTML={{ __html: termsConditions.content.paragraph }} />
							<Button href="/" maxWidth={'200px'}><i className="fa fa-angle-left" /> {'Back to home'}</Button>
						</Column>
					</Row>
				</Container>
			</Section>
		</Layout>
	)
}

export default TermsConditions
